const maxContainerWidth = 99.4 * 10;
const maxWindowWidth = 1920;

document.addEventListener('DOMContentLoaded', function () {
  const track = document.querySelector('.done-section-review-carousel-inner');
  const slides = Array.from(track.children);
  const navBullets = document.querySelectorAll(
    '.done-section-review-carousel-bullet'
  );

  let slideWidth = getSlideWidth();
  let centerOffset = (track.innerWidth - slideWidth) / 2;
  let currentIndex = 0;

  function getContainerWidth() {
    return Math.min(window.innerWidth, maxWindowWidth);
  }

  function getSlideWidth() {
    let containerWidth = getContainerWidth();
    const isBlog = track.classList.contains('blog');
    const isMobile = window.matchMedia('(max-width: 992px)').matches;

    if (isBlog) {
      containerWidth = Math.min(containerWidth, maxContainerWidth);
    }

    if (isMobile) {
      return Math.floor(containerWidth * 0.75);
    } else {
      return Math.floor(containerWidth * (isBlog ? 0.5 : 0.3333));
    }
  }

  function updateSlides() {
    slideWidth = getSlideWidth();
    slides.forEach((slide, index) => {
      slide.style.left = `${slideWidth * index}px`;
    });
    let containerWidth = getContainerWidth();
    centerOffset = (containerWidth - slideWidth) / 2;
    centerOffsetTooFew = (containerWidth - slideWidth) / 6;
  }

  function moveToSlide(targetIndex) {
    var amountToMove = slideWidth * targetIndex;

    track.style.transform = `translateX(-${amountToMove}px)`;
    currentIndex = targetIndex;
    updateNavBullets();
    updateActiveSlide();
  }

  function updateNavBullets() {
    navBullets.forEach((bullet, index) => {
      bullet.classList.toggle('active', index === currentIndex);
    });
  }

  function updateActiveSlide() {
    slides.forEach((slide, index) => {
      if (index === currentIndex) {
        slide.classList.add('active');
        slide.style.opacity = 1;
      } else if (index === currentIndex - 2 || index === currentIndex + 2) {
        slide.style.opacity = 0;
        slide.classList.remove('active');
      } else {
        slide.style.opacity = 1;
        slide.classList.remove('active');
      }
    });

    slideOffset = currentIndex * slideWidth;
    adjustment = centerOffset - slideOffset;

    adjustmentTooFew = centerOffsetTooFew - slideOffset;
    const isMobile = window.matchMedia('(max-width: 992px)').matches;

    const finalAdjustment =
      track.classList.contains('toofew') && !isMobile
        ? adjustmentTooFew
        : adjustment;

    track.style.transform = `translateX(${finalAdjustment}px)`;
  }

  function handleNavClick(targetIndex) {
    moveToSlide(targetIndex);
  }

  function handleKeyDown(e) {
    if (e.key === 'ArrowLeft') {
      moveToSlide(currentIndex > 0 ? currentIndex - 1 : slides.length - 1);
    } else if (e.key === 'ArrowRight') {
      moveToSlide(currentIndex < slides.length - 1 ? currentIndex + 1 : 0);
    }
  }

  navBullets.forEach((bullet, index) => {
    bullet.addEventListener('click', () => {
      if (index !== currentIndex) {
        handleNavClick(index);
      }
    });
    bullet.addEventListener('focus', () => {
      moveToSlide(index);
    });
  });
  slides.forEach((slide, index) => {
    slide.addEventListener('click', () => {
      if (index !== currentIndex) {
        handleNavClick(index);
      }
    });
  });

  let touchstartX = 0;

  function hSwipe(touchstartX, touchendX, currentIndex, slidesLength) {
    if (touchendX < touchstartX) {
      return currentIndex < slidesLength - 1 ? currentIndex + 1 : 0;
    } else if (touchendX > touchstartX) {
      return currentIndex > 0 ? currentIndex - 1 : slidesLength - 1;
    } else {
      return currentIndex;
    }
  }

  document.addEventListener('touchstart', (e) => {
    touchstartX = e.changedTouches[0].screenX;
  });

  document.addEventListener('touchend', (e) => {
    const touchendX = e.changedTouches[0].screenX;
    const newIndex = hSwipe(
      touchstartX,
      touchendX,
      currentIndex,
      slides.length
    );
    moveToSlide(newIndex);
  });

  document.addEventListener('keydown', handleKeyDown);
  window.addEventListener('resize', function () {
    updateSlides();
    updateActiveSlide();
  });

  updateSlides();
  updateActiveSlide();
});
